import styled from 'styled-components';

const BurgerMenuStyle = styled.div`
	--burgerInnerPadTB: var(--sp10x);
	--numberMTop: var(--sp5x);

	position: fixed;
	width: 100vw;
	top: 0;
	bottom: 0;
	transform: translate3d(100%, 0, 0);
	background-color: var(--backgroundDarkColor);
	transition: transform 0.4s ease-out;
	padding-top: var(--headerHeight);
	z-index: 2;

	&.active {
		transform: translate3d(0, 0, 0);
	}

	.burger-inner-wrapper {
		padding: var(--burgerInnerPadTB) 0;
	}

	.burger-menu-items-inner-block {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.burger-menu-item-block {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;

		&:not(:first-child) {
			margin-top: var(--numberMTop);
		}

		&.active-item {
			.burger-menu-number,
			.burger-menu-item {
				color: var(--primaryColor7);
			}

			.link-item {
				pointer-events: none;
				cursor: default;

				&::before {
					background-color: var(--primaryColor7);
				}
			}
		}

		.btn-wrap {
			display: none;
		}
	}

	.burger-menu-item {
		margin-top: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--burgerInnerPadTB: var(--sp7x);
		--numberMTop: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--burgerInnerPadTB: var(--sp6x);
		--numberMTop: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--burgerInnerPadTB: var(--sp8x);
		--numberMTop: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXS}) {
		--burgerInnerPadTB: var(--sp4x);
		--numberMTop: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--burgerInnerPadTB: var(--sp6x);
		--numberMTop: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--burgerInnerPadTB: var(--sp6x);
		--numberMTop: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--burgerInnerPadTB: var(--sp4x);
		--numberMTop: var(--sp3x);

		.burger-menu-items-block {
			overflow-y: auto;
			-ms-overflow-style: none;
			scrollbar-width: none;
			height: calc(100vh - var(--headerHeight) - 2 * var(--burgerInnerPadTB));

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.burger-menu-items-block .btn-wrap {
			display: block;
			margin-top: 40px;
		}
	}
`;

export default BurgerMenuStyle;
