'use client';

import { memo, useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { gsap } from 'gsap';

//* HOC's
import { withUIContext, withDataContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '../Text';
import Image from '../Image';
import Button from '../Button';
import BurgerMenu from './BurgerMenu';

//* Style
import HeaderStyle from './style';

const AppHeader = memo(({ winWidth, isBurgerMenuOpen, toggleBurgerMenu, closeBurgerMenu, preloader, getGlobalData }) => {
	//! Global Data
	const globalData = getGlobalData();

	//! Next Navigation
	const pathname = usePathname();
	const router = useRouter();

	//! Refs
	const headerRef = useRef();
	const logoRef = useRef();
	const headerBtnRef = useRef();
	const burgerBtnRef = useRef();

	//! States
	const [y, setY] = useState();
	const [scrollDirection, setScrollDirection] = useState(true);
	const [isHeaderScroll, setIsHeaderScroll] = useState(false);

	//! Check Not Found Page
	const isNotFound = useMemo(() => {
		return pathname === '/not-found/';
	}, [pathname]);

	//! Handle Navigation
	const handleNavigation = useCallback(
		(e) => {
			const window = e.currentTarget;

			if (y >= window.scrollY || y <= 0 || window.scrollY <= 0) {
				setScrollDirection(true);
			} else {
				setScrollDirection(false);
			}

			setY(window.scrollY);
		},
		[y]
	);

	//! Handle Logo Click
	const handleLogoClick = useCallback(() => {
		closeBurgerMenu();

		if (pathname !== '/') {
			router.push('/');
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [pathname]);

	//! Close Burger Menu
	useEffect(() => {
		closeBurgerMenu();

		setIsHeaderScroll('');
		setScrollDirection(true);
	}, [pathname]);

	//! Header Scroll
	useEffect(() => {
		if (headerRef.current) {
			y > headerRef.current.offsetHeight || window.scrollY > headerRef.current.offsetHeight ? setIsHeaderScroll(true) : setIsHeaderScroll(false);
		}
	}, [y, headerRef.current]);

	//! Add Event Listener
	useEffect(() => {
		if (!isBurgerMenuOpen) {
			window.addEventListener('scroll', handleNavigation);

			return () => {
				window.removeEventListener('scroll', handleNavigation);
			};
		}
	}, [handleNavigation, isBurgerMenuOpen]);

	useEffect(() => {
		if (!isNotFound) {
			if (!preloader) {
				gsap.fromTo(
					[logoRef.current, burgerBtnRef.current],
					{
						opacity: 0,
					},
					{
						opacity: 1,
						duration: 0.4,
						ease: 'easeOut',
					}
				);

				if (winWidth >= 768) {
					gsap.fromTo(
						headerBtnRef.current,
						{
							opacity: 0,
						},
						{
							opacity: 1,
							duration: 0.4,
							ease: 'easeOut',
						}
					);
				}
			}
		}
	}, [preloader, winWidth, isNotFound]);

	const itemWithHeaderHasBackground = Object.values(config.routes).find((item) => item.headerHasBackground && pathname.includes(item.path));

	return !isNotFound ? (
		<>
			<HeaderStyle
				ref={headerRef}
				className={`header ${isHeaderScroll || !!itemWithHeaderHasBackground ? 'scroll-background' : ''} ${isBurgerMenuOpen ? 'transparent-background' : ''} ${scrollDirection || isBurgerMenuOpen ? 'visible' : ''}`}>
				<div className='header-inner-wrapper'>
					<Image
						priority
						ref={logoRef}
						alt='App Logo'
						onClick={handleLogoClick}
						className='contain logo cursor-pointer'
						src={`${isHeaderScroll || isBurgerMenuOpen || !!itemWithHeaderHasBackground || isNotFound ? '/images/logoDark.svg' : '/images/logoWhite.svg'}`}
					/>

					<div className='header-right-part-wrapper'>
						{winWidth >= 768 ? (
							<div
								ref={headerBtnRef}
								className='btn-block'>
								<Button
									target='_blank'
									text='bookOnline'
									className='default primary'
									url={globalData?.book_online_link}
								/>
							</div>
						) : null}

						<Text
							ref={burgerBtnRef}
							onClick={toggleBurgerMenu}
							text={`${isBurgerMenuOpen ? 'close' : 'menu'}`}
							className={`p p2 workSans-medium uppercase burger-menu-btn burger-menu-line ${isHeaderScroll || isBurgerMenuOpen || !!itemWithHeaderHasBackground || isNotFound ? 'primary-color1 version1' : 'background-light-color version2'}`}
						/>
					</div>
				</div>
			</HeaderStyle>

			<BurgerMenu />
		</>
	) : null;
});

export default withDataContext(withUIContext(AppHeader, ['winWidth', 'isBurgerMenuOpen', 'toggleBurgerMenu', 'closeBurgerMenu', 'preloader']), ['getGlobalData']);
