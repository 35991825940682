import { memo as Memo, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { usePathname } from 'next/navigation';

//* HOC's
import { withUIContext, withDataContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '../../Text';
import Button from '../../Button';
import Container from '../../Container';
import CustomLink from '../../CustomLink';

//* Style
import BurgerMenuStyle from './style';

const BurgerMenu = Memo(({ winWidth, className, isBurgerMenuOpen, getGlobalData }) => {
	//! Global Data
	const globalData = getGlobalData();

	//! Next Navigation
	const pathname = usePathname();

	//! Refs
	const burgerItem1Ref = useRef();
	const burgerItem2Ref = useRef();
	const burgerItem3Ref = useRef();
	const burgerItem4Ref = useRef();
	const burgerItem5Ref = useRef();
	const burgerItem6Ref = useRef();
	const burgerItem7Ref = useRef();
	const burgerItem8Ref = useRef();
	const burgerItem9Ref = useRef();
	const burgerItem10Ref = useRef();
	const burgerBtnMobileRef = useRef();

	//! Animations
	useEffect(() => {
		if (isBurgerMenuOpen) {
			gsap.fromTo(
				[burgerItem1Ref?.current, burgerItem2Ref?.current, burgerItem3Ref?.current, burgerItem4Ref?.current, burgerItem5Ref?.current, burgerItem6Ref?.current, burgerItem7Ref?.current, burgerItem8Ref?.current, burgerItem9Ref?.current, burgerItem10Ref?.current],
				{
					css: { transform: 'translate3d(800px,0,0)' },
				},
				{
					css: { transform: 'translate3d(0,0,0)' },
					stagger: 0.04,
					duration: 0.7,
					ease: 'easeOut',
				}
			);

			if (!(winWidth >= 768)) {
				gsap.fromTo(
					burgerBtnMobileRef.current,
					{
						css: { transform: 'translate3d(800px,0,0)' },
					},
					{
						css: { transform: 'translate3d(0,0,0)' },
						delay: 0.5,
						duration: 0.7,
						ease: 'easeOut',
					}
				);
			}
		}
	}, [winWidth, isBurgerMenuOpen]);

	return (
		<BurgerMenuStyle className={`burger-menu ${className || ''} ${isBurgerMenuOpen ? 'active' : ''}`}>
			<div className='burger-inner-wrapper'>
				<Container row>
					<div className='empty col-2 col-dl-3 col-dm-2 col-ds-2 col-dxs-2 col-t-12 col-ts-12 col-m-12' />

					<div className='burger-menu-items-block col-8 col-dl-6 col-dm-8 col-ds-8 col-dxs-8 col-t-12 col-ts-12 col-m-12'>
						<div className='burger-menu-items-inner-block'>
							<div className={`burger-menu-item-block ${pathname === `${config.routes.services.path}/` ? 'active-item' : ''}`}>
								<Text
									ref={burgerItem1Ref}
									className={`p p2 primary-color1 workSans-medium burger-menu-number`}>
									01
								</Text>

								<CustomLink
									className='burger-menu-line version1'
									url={`${config.routes.services.path}/`}
									ref={burgerItem2Ref}>
									<Text
										className={`h3 primary-color1 workSans-regular uppercase burger-menu-item`}
										text={'ourServices'}
									/>
								</CustomLink>
							</div>

							<div className={`burger-menu-item-block ${pathname === `${config.routes.team.path}/` ? 'active-item' : ''}`}>
								<Text
									ref={burgerItem3Ref}
									className={`p p2 primary-color1 workSans-medium burger-menu-number`}>
									02
								</Text>

								<CustomLink
									className='burger-menu-line version1'
									url={`${config.routes.team.path}/`}
									ref={burgerItem4Ref}>
									<Text
										className={`h3 primary-color1 workSans-regular uppercase burger-menu-item`}
										text={'team'}
									/>
								</CustomLink>
							</div>

							<div className={`burger-menu-item-block ${pathname === `${config.routes.digitalPractices.path}/` ? 'active-item' : ''}`}>
								<Text
									ref={burgerItem5Ref}
									className={`p p2 primary-color1 workSans-medium burger-menu-number`}>
									03
								</Text>

								<CustomLink
									className='burger-menu-line version1'
									url={`${config.routes.digitalPractices.path}/`}
									ref={burgerItem6Ref}>
									<Text
										className={`h3 primary-color1 workSans-regular uppercase burger-menu-item`}
										text={'digitalPractices'}
									/>
								</CustomLink>
							</div>

							<div className={`burger-menu-item-block ${pathname === `${config.routes.philosophy.path}/` ? 'active-item' : ''}`}>
								<Text
									ref={burgerItem7Ref}
									className={`p p2 primary-color1 workSans-medium burger-menu-number`}>
									04
								</Text>

								<CustomLink
									className='burger-menu-line version1'
									url={`${config.routes.philosophy.path}/`}
									ref={burgerItem8Ref}>
									<Text
										className={`h3 primary-color1 workSans-regular uppercase burger-menu-item`}
										text={'philosophy'}
									/>
								</CustomLink>
							</div>

							<div className={`burger-menu-item-block ${pathname === `${config.routes.contacts.path}/` ? 'active-item' : ''}`}>
								<Text
									ref={burgerItem9Ref}
									className={`p p2 primary-color1 workSans-medium burger-menu-number`}>
									05
								</Text>

								<CustomLink
									className='burger-menu-line version1'
									url={`${config.routes.contacts.path}/`}
									ref={burgerItem10Ref}>
									<Text
										className={`h3 primary-color1 workSans-regular uppercase burger-menu-item`}
										text={'contacts'}
									/>
								</CustomLink>
							</div>

							{!(winWidth >= 768) ? (
								<div ref={burgerBtnMobileRef}>
									<Button
										target='_blank'
										text='bookOnline'
										className='default primary'
										url={globalData?.book_online_link}
									/>
								</div>
							) : null}
						</div>
					</div>

					<div className='empty col-2 col-dl-3 col-dm-2 col-ds-2 col-dxs-2 col-t-12 col-ts-12 col-m-12' />
				</Container>
			</div>
		</BurgerMenuStyle>
	);
});

export default withDataContext(withUIContext(BurgerMenu, ['winWidth', 'isBurgerMenuOpen']), ['getGlobalData']);
