import styled from 'styled-components';

const FooterStyle = styled.footer`
	--ftBlockLargePadTB: var(--sp10x);
	--ftBlockDistance: var(--sp3x);
	--ftBlockNormalPadTB: var(--sp8x);
	--ftBlockSmallPadTB: var(--sp4x);
	--iconDistance: var(--sp6x);
	--iconSize: var(--sp7x);
	--ftBlockWidth: 33.3%;

	--faqDistance: var(--sp5x);

	margin-top: var(--sectionDistance);
	padding: 0 var(--contPaddingLR);

	&.no-margin {
		margin-top: 0;
	}

	.ft-content-wrapper {
		display: flex;
		margin: 0 calc(-1 * var(--ftBlockDistance) / 2);

		&.last {
			align-items: center;

			.ft-last-col {
				.link-item {
					&:first-child {
						margin-right: calc(var(--faqDistance) / 2);
					}

					&:last-child {
						margin-left: calc(var(--faqDistance) / 2);
					}
				}
			}
		}
	}

	.ft-block {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: var(--ftBlockWidth);
		margin: 0 calc(var(--ftBlockDistance) / 2);

		&.large {
			padding: var(--ftBlockLargePadTB) 0;
		}

		&.normal {
			padding: var(--ftBlockNormalPadTB) 0;
		}
	}

	.ft-horizontal-line {
		width: 0;
		height: 1px;
		background-color: var(--primaryColor1);
	}

	.ft-vertical-line {
		width: 1px;
		height: auto;
		opacity: 0;
		margin: 0 calc(var(--ftBlockDistance) / 2);
		background-color: var(--primaryColor1);
	}

	.ft-last-col {
		padding: var(--ftBlockSmallPadTB) 0;
		margin: 0 calc(var(--ftBlockDistance) / 2);

		&.flex-col {
			flex: 1 1 0%;
		}

		&.flex-end {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	.a-style {
		margin-top: var(--sp1x);
		text-align: center;
	}

	.socials-block {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin: 0 calc(-1 * var(--iconDistance) / 2);
	}

	.icon-style {
		margin: 0 calc(var(--iconDistance) / 2);

		i {
			display: inline-flex;
			font-size: var(--iconSize);
			transition: all var(--trTime) ease-out;

			@media (hover: hover) {
				&:hover {
					transform: scale(1.2);
				}
			}
		}
	}

	.ft-second-wrapper {
		display: flex;
		margin: 0 calc(-1 * var(--ftBlockDistance) / 2);

		.left-column,
		.right-column {
			padding: var(--ftBlockNormalPadTB) calc(var(--ftBlockDistance) / 2);
		}

		.left-column {
			width: 33.3%;
		}

		.right-column {
			width: calc(100% - 33.3%);
		}

		.right-inner-column {
			display: flex;
			margin: 0 calc(-1 * var(--ftBlockDistance) / 2);
		}

		.flex-block {
			width: 50%;
			padding: 0 calc(var(--ftBlockDistance) / 2);
		}

		.flex-inner-block {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--ftBlockLargePadTB: var(--sp8x);
		--ftBlockNormalPadTB: var(--sp6x);
		--ftBlockDistance: var(--sp2-5x);
		--ftBlockSmallPadTB: var(--sp3x);
		--iconDistance: var(--sp5x);
		--iconSize: var(--sp5x);

		--faqDistance: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--ftBlockLargePadTB: var(--sp7x);
		--ftBlockNormalPadTB: var(--sp3x);
		--ftBlockDistance: var(--sp4x);
		--ftBlockSmallPadTB: var(--sp2-5x);
		--iconDistance: var(--sp3-5x);
		--iconSize: var(--sp4x);

		--faqDistance: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--ftBlockLargePadTB: var(--sp6x);
		--ftBlockNormalPadTB: var(--sp3x);
		--ftBlockDistance: var(--sp3-5x);
		--ftBlockSmallPadTB: var(--sp2x);
		--iconDistance: var(--sp3-5x);
		--iconSize: var(--sp3-5x);

		--faqDistance: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXS}) {
		--ftBlockLargePadTB: var(--sp6x);
		--ftBlockNormalPadTB: var(--sp3x);
		--ftBlockDistance: var(--sp3-5x);
		--ftBlockSmallPadTB: var(--sp2x);
		--iconDistance: var(--sp3x);
		--iconSize: var(--sp3-5x);

		--faqDistance: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--ftBlockLargePadTB: var(--sp6x);
		--ftBlockNormalPadTB: var(--sp3x);
		--ftBlockDistance: var(--sp3-5x);
		--ftBlockSmallPadTB: var(--sp2x);
		--iconDistance: var(--sp3x);
		--iconSize: var(--sp3x);

		--faqDistance: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--ftBlockLargePadTB: var(--sp3x);
		--ftBlockNormalPadTB: var(--sp3x);
		--ftBlockDistance: var(--sp3x);
		--ftBlockSmallPadTB: var(--sp2x);
		--iconDistance: var(--sp3x);
		--iconSize: var(--sp3x);

		--faqDistance: unset;

		.footer-inner-wrapper {
			overflow: hidden;
		}

		.ft-second-wrapper {
			flex-direction: column;

			.left-column,
			.right-column {
				width: 100%;
			}

			.right-inner-column {
				position: relative;
			}

			.absolute {
				position: absolute;
				top: calc(-1 * var(--ftBlockNormalPadTB));
				left: calc(var(--ftBlockDistance) / 2);
			}
		}

		.a-style {
			margin-top: 0;
		}

		.tablet-mobile-version {
			margin: 0 !important;
			display: flex;
			justify-content: space-between;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--ftBlockLargePadTB: var(--sp2-5x);
		--ftBlockNormalPadTB: var(--sp2-5x);
		--ftBlockDistance: var(--sp2x);
		--ftBlockSmallPadTB: var(--sp1-5x);
		--iconDistance: var(--sp2x);
		--iconSize: var(--sp2-5x);

		--faqDistance: unset;

		.tablet-mobile-version {
			margin: 0 !important;
			display: flex;
			justify-content: space-between;
		}

		.footer-inner-wrapper {
			overflow: hidden;
		}

		.ft-second-wrapper {
			flex-direction: column;

			.left-column,
			.right-column {
				width: 100%;
			}

			.right-inner-column {
				position: relative;
			}

			.absolute {
				position: absolute;
				top: calc(-1 * var(--ftBlockNormalPadTB));
				left: calc(var(--ftBlockDistance) / 2);
			}
		}

		.ft-content-wrapper {
			&.last {
				flex-wrap: wrap;
				align-items: unset;
				justify-content: unset;

				.ft-last-col {
					width: 100%;

					&:last-child {
						padding-top: 0;
					}
				}
			}
		}

		.a-style {
			margin-top: 0;
			word-break: break-word;
		}

		.ft-last-col {
			&.flex-col {
				flex: unset !important;
			}

			&.flex-end {
				display: unset !important;
				align-items: unset !important;
				justify-content: unset !important;
			}
		}
	}
`;

export default FooterStyle;
