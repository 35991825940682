import styled from 'styled-components';

const HeaderStyle = styled.header`
	--headerPadTB: var(--sp4x);
	--headerPadLR: var(--sp8x);
	--logoWidth: var(--sp26x);
	--logoHeight: var(--sp8x);
	--headerBtnAndBurgerMenuDistance: var(--sp5x);

	position: fixed;
	top: 0;
	width: 100%;
	padding: var(--headerPadTB) var(--headerPadLR);
	transform: translate3D(0, -100%, 0);
	transition: transform var(--trTime);
	z-index: 3;

	&.visible {
		transform: translate3D(0, 0, 0);
	}

	&.scroll-background {
		background-color: var(--backgroundLightColor);
	}

	&.transparent-background {
		background-color: transparent;
	}

	&.colored {
		background-color: var(--backgroundLightColor);
	}

	.header-inner-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.header-right-part-wrapper {
		display: flex;
		align-items: center;
		margin: 0 calc(-1 * var(--headerBtnAndBurgerMenuDistance) / 2);
	}

	.logo {
		width: var(--logoWidth);
		height: var(--logoHeight);
		padding-top: unset;
		opacity: 0;
	}

	.btn-block,
	.burger-menu-btn {
		margin: 0 calc(var(--headerBtnAndBurgerMenuDistance) / 2);
	}

	.burger-menu-btn {
		opacity: 0;
		cursor: pointer;
	}

	.btn-block {
		opacity: 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--headerPadTB: var(--sp3x);
		--headerPadLR: var(--sp5x);
		--logoWidth: var(--sp20x);
		--logoHeight: var(--sp6x);
		--headerBtnAndBurgerMenuDistance: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--headerPadTB: var(--sp2-5x);
		--headerPadLR: var(--sp5x);
		--logoWidth: var(--sp16x);
		--logoHeight: var(--sp5x);
		--headerBtnAndBurgerMenuDistance: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--headerPadTB: var(--sp2x);
		--headerPadLR: var(--sp4x);
		--logoWidth: var(--sp16x);
		--logoHeight: var(--sp5x);
		--headerBtnAndBurgerMenuDistance: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXS}) {
		--headerPadTB: var(--sp2x);
		--headerPadLR: var(--sp4x);
		--logoWidth: var(--sp16x);
		--logoHeight: var(--sp5x);
		--headerBtnAndBurgerMenuDistance: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--headerPadTB: var(--sp2x);
		--headerPadLR: var(--sp3x);
		--logoWidth: var(--sp16x);
		--logoHeight: var(--sp5x);
		--headerBtnAndBurgerMenuDistance: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--headerPadTB: var(--sp2x);
		--headerPadLR: var(--sp3x);
		--logoWidth: var(--sp16x);
		--logoHeight: var(--sp5x);
		--headerBtnAndBurgerMenuDistance: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--headerPadTB: var(--sp2x);
		--headerPadLR: var(--sp2x);
		--logoWidth: var(--sp13x);
		--logoHeight: var(--sp4x);
		--headerBtnAndBurgerMenuDistance: var(--sp1x);

		.header-right-part-wrapper .btn-block {
			display: none;
		}
	}
`;

export default HeaderStyle;
