'use client';

import { memo, useRef, useEffect, useMemo } from 'react';
import { gsap } from 'gsap';
import { usePathname } from 'next/navigation';

//* HOC's
import { withDataContext, withLanguageContext, withUIContext } from '@/context';

//* Helpers
import { config, useActiveSection } from '@/helpers';

//* Components
import Text from '../Text';
import Icon from '../Icon';
import Button from '../Button';
import CustomLink from '../CustomLink';

//* Style
import FooterStyle from './style';

const AppFooter = memo(({ translate, winWidth, toggleBurgerMenu, getGlobalData }) => {
	//! Global Data
	const globalData = getGlobalData();

	//! Next Navigation
	const pathname = usePathname();

	//! Refs
	const containerRef = useRef();
	const horizontalLine1 = useRef();
	const horizontalLine2 = useRef();
	const horizontalLine3 = useRef();
	const horizontalLineTabletAndMobileVersion1 = useRef();
	const horizontalLineTabletAndMobileVersion2 = useRef();
	const verticalLine1 = useRef();
	const verticalLine2 = useRef();
	const isAnimated = useRef();

	//! Check Not Found Page
	const isNotFound = useMemo(() => {
		return pathname === '/not-found/';
	}, [pathname]);

	//! Active Section
	const activeSection = useActiveSection(containerRef, 100);

	//! One Time Animation
	useEffect(() => {
		isAnimated.current = false;
	}, [pathname]);

	useEffect(() => {
		if (activeSection && !isAnimated.current) {
			gsap.fromTo(
				[horizontalLine1?.current, horizontalLine2?.current, horizontalLine3?.current],
				{
					width: 0,
				},
				{
					width: '100%',
					stagger: 0.2,
					duration: 0.9,
					delay: 0.2,
					ease: 'easeOut',
				}
			);

			if (!(winWidth >= 1280)) {
				gsap.fromTo(
					[horizontalLineTabletAndMobileVersion1.current, horizontalLineTabletAndMobileVersion2.current],
					{
						width: 0,
					},
					{
						width: '100%',
						stagger: 0.2,
						duration: 0.9,
						delay: 0.55,
						ease: 'easeOut',
					}
				);
			}

			gsap.fromTo(
				[verticalLine1.current, verticalLine2.current],
				{
					height: 0,
					opacity: 0,
				},
				{
					height: 'auto',
					stagger: 0.5,
					duration: 0.6,
					delay: 0.3,
					ease: 'easeOut',
					opacity: 1,
				}
			);

			isAnimated.current = true;
		}
	}, [activeSection, winWidth]);

	return !isNotFound ? (
		<FooterStyle className={`footer ${pathname === `${config.routes.team.path}/` || pathname === `${config.routes.digitalPractices.path}/` ? 'no-margin' : ''}`}>
			<div
				ref={containerRef}
				className='footer-inner-wrapper'>
				<div
					ref={horizontalLine1}
					className='ft-horizontal-line'
				/>

				<div className='ft-content-wrapper first'>
					<div className='ft-block large'>
						<Button
							text='menu'
							onClick={toggleBurgerMenu}
							className={`link large ${winWidth > 1280 ? 'line-anim' : 'underline'}`}
						/>
					</div>

					<div
						ref={verticalLine1}
						className='ft-vertical-line'
					/>

					<div className='ft-block large'>
						<div className='socials-block'>
							<a
								target='_blank'
								className='icon-style'
								rel='noopener noreferrer'
								href={globalData.socials.linkedin}
								aria-label={globalData.socials.linkedin}>
								<Icon name='linkedin' />
							</a>

							{/* <a
								target='_blank'
								className='icon-style'
								rel='noopener noreferrer'
								href={globalData.socials.twitter}
								aria-label={globalData.socials.twitter}>
								<Icon name='x' />
							</a> */}

							<a
								target='_blank'
								className='icon-style'
								rel='noopener noreferrer'
								href={globalData.socials.instagram}
								aria-label={globalData.socials.instagram}>
								<Icon name='instagram' />
							</a>
						</div>
					</div>

					<div
						ref={verticalLine2}
						className='ft-vertical-line'
					/>

					<div className='ft-block large'>
						<Button
							target='_blank'
							text='bookOnline'
							className={`link large ${winWidth > 1280 ? 'line-anim' : 'underline'}`}
							url={globalData.book_online_link}
						/>
					</div>
				</div>

				<div
					ref={horizontalLine2}
					className='ft-horizontal-line'
				/>

				<div className='ft-second-wrapper'>
					<div className='left-column'>
						<div className='flex-inner-block'>
							<Text
								className='p p1 primary-color1 workSans-semibold uppercase'
								text='addressWithSymbol'
							/>

							{globalData.google_map_link ? (
								<a
									target='_blank'
									className='a-style'
									rel='noopener noreferrer'
									href={globalData.google_map_link}
									aria-label={globalData.address.name}>
									<Text className='p p1 primary-color1 workSans-regular uppercase'>{globalData.address.name}</Text>

									<Text className='p p1 primary-color1 workSans-regular uppercase'>{globalData.address.line_1}</Text>

									<Text className='p p1 primary-color1 workSans-regular uppercase'>{globalData.address.line_2}</Text>
								</a>
							) : (
								<>
									<Text className='p p1 primary-color1 workSans-regular uppercase a-style'>{globalData.address.name}</Text>

									<Text className='p p1 primary-color1 workSans-regular uppercase'>{globalData.address.line_1}</Text>

									<Text className='p p1 primary-color1 workSans-regular uppercase'>{globalData.address.line_2}</Text>
								</>
							)}
						</div>
					</div>

					<div className='right-column'>
						<div className='right-inner-column'>
							{!(winWidth >= 1280) ? (
								<div
									ref={horizontalLineTabletAndMobileVersion1}
									className='ft-horizontal-line absolute'
								/>
							) : null}

							<div className='flex-block'>
								<div className='flex-inner-block'>
									<Text
										className='p p1 primary-color1 workSans-semibold uppercase'
										text='telephoneWithSymbol'
									/>

									<a
										className='a-style'
										rel='noopener noreferrer'
										aria-label={globalData.phone_number}
										href={`tel:+${globalData.phone_number}`}>
										<Text className='p p1 primary-color1 workSans-regular'>{globalData.phone_number}</Text>
									</a>
								</div>
							</div>

							<div className='flex-block'>
								<div className='flex-inner-block'>
									<Text
										className='p p1 primary-color1 workSans-semibold uppercase'
										text='emailAddressWithSymbol'
									/>

									<a
										className='a-style'
										rel='noopener noreferrer'
										aria-label={globalData.email}
										href={`mailto:${globalData.email}`}>
										<Text className='p p1 primary-color1 workSans-regular'>{globalData.email}</Text>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					ref={horizontalLine3}
					className='ft-horizontal-line'
				/>

				{!(winWidth >= 1280) ? (
					<>
						<div className='ft-last-col tablet-mobile-version'>
							<CustomLink
								url={config.routes.dataProtection.path}
								className={`p p3 primary-color1 workSans-medium uppercase ${winWidth > 1280 ? 'line-anim' : 'underline'}`}
								content={translate(config.routes.dataProtection.name)}
							/>

							<CustomLink
								url={config.routes.imprint.path}
								className={`p p3 primary-color1 workSans-medium uppercase ${winWidth > 1280 ? 'line-anim' : 'underline'}`}
								content={translate(config.routes.imprint.name)}
							/>
						</div>

						<div
							ref={horizontalLineTabletAndMobileVersion2}
							className='ft-horizontal-line'
						/>
					</>
				) : null}

				<div className='ft-content-wrapper last'>
					<div className='ft-last-col flex-col'>
						<Text className='p p3 primary-color1 workSans-medium uppercase'>
							© {new Date().getFullYear()} {translate('allRightsReserved')}
						</Text>
					</div>

					{winWidth >= 1280 ? (
						<div className='ft-last-col'>
							<CustomLink
								url={config.routes.dataProtection.path}
								className={`p p3 primary-color1 workSans-medium uppercase ${winWidth > 1280 ? 'line-anim' : 'underline'}`}
								content={translate(config.routes.dataProtection.name)}
							/>

							<CustomLink
								url={config.routes.imprint.path}
								className={`p p3 primary-color1 workSans-medium uppercase ${winWidth > 1280 ? 'line-anim' : 'underline'}`}
								content={translate(config.routes.imprint.name)}
							/>
						</div>
					) : null}

					<div className='ft-last-col flex-col flex-end'>
						<Text className='p p3 primary-color1 workSans-medium uppercase'>
							{translate('designedAndDevelopmentBy')}

							<a
								target='_blank'
								rel='noopener noreferrer'
								href={'https://conceptstudio.com/'}
								aria-label={'https://conceptstudio.com/'}
								className={`${winWidth > 1280 ? 'line-anim' : 'underline'}`}>
								Concept Studio
							</a>
						</Text>
					</div>
				</div>
			</div>
		</FooterStyle>
	) : null;
});

export default withLanguageContext(withUIContext(withDataContext(AppFooter, ['getGlobalData']), ['winWidth', 'toggleBurgerMenu']), ['translate']);
