import styled from 'styled-components';

const PreloaderStyle = styled.section`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--primaryColor1);
	z-index: 99999999;
`;

export default PreloaderStyle;
