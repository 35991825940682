'use client';

import { memo, useEffect, useState, useRef, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import gsap from 'gsap';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import PreloaderStyle from './style';

const Preloader = memo(({ preloader, disablePreloader }) => {
	//! Next Navigation
	const pathname = usePathname();

	//! Ref
	const contRef = useRef();

	//! State
	const [percent, setPercent] = useState(0);

	//! Check Not Found Page
	const isNotFound = useMemo(() => {
		return pathname === '/not-found/';
	}, [pathname]);

	//! Did Mount
	useEffect(() => {
		let num = 0;

		function imgLoad(img) {
			let imgL = document.images.length;

			if (img) {
				img.src = img.getAttribute('src');
			}

			setTimeout(function () {
				setPercent(Math.ceil((imgL === 1 ? 1 : num / (imgL - 1)) * 100));

				num++;

				if (num < imgL) {
					imgLoad(document.images[num]);
				}
			}, 50);
		}

		imgLoad(document.images[num]);
	}, []);

	//! Animate after end
	useEffect(() => {
		if (percent === 100 || isNotFound) {
			gsap.to(contRef.current, { delay: 0.5, duration: 1, y: '-100%', onComplete: () => disablePreloader() });
		}
	}, [percent, isNotFound]);

	return preloader ? <PreloaderStyle ref={contRef} /> : null;
});

export default withUIContext(Preloader, ['preloader', 'disablePreloader']);
